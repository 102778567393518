/*!
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@charset "utf-8";

/*
  This is an import file for the CSS for the Warehouse project (PyPI).
  This stylesheet is used to override any styling that will cause issues
  for users without Javascript (or have it disabled).
 */

.notification-bar {
  // Default notifications to be visible, even if they're dismissed
  // Also, hide any elements that suggest dismiss-ability since js is disabled
  display: block;

  &__dismiss {
    display: none;
  }

  &--hidden,
  &--visible {
    display: inherit;
  }
}

.stick-to-top {
  // Avoid hiding navigation bar with notification
  position: relative;
}
